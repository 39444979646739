import React from 'react';
import PropTypes from 'prop-types';
import { loaderConstants } from '../../../constants/constants';
import Error from '../../shared/error';
import KitListFilterHeader from '../header/kit-list-filter-header';
import KitListSortableHeader from './kit-list-sortable-header';
import KitList from './kit-list';
import KitListFooter from './kit-list-footer';
import Loader from '../../shared/loader';

export default class KitListContainer extends React.Component {
    renderContent() {
        return (
            <table className="regular no-borders overflow-ellipsis hover-row">
                <KitListSortableHeader
                    locations={this.props.locations}
                    onOrderByChange={this.props.onOrderByChange}
                    onSelectAllToggle={this.props.onSelectAllToggle}
                    kitColumns={this.props.kitColumns}
                    kits={this.props.kits}
                    selectedKits={this.props.selectedKits}
                    selectableKits={this.props.selectableKits}
                    nonSortableColumns={this.props.nonSortableColumns}
                />
                <KitList
                    locations={this.props.locations}
                    kits={this.props.kits}
                    selectedKits={this.props.selectedKits}
                    selectableKits={this.props.selectableKits}
                    onKitSelected={this.props.onKitSelected}
                />
                <KitListFooter
                    kitsTotalCount={this.props.kitsTotalCount}
                    kitsFetchedCount={this.props.kitsFetchedCount}
                    pageIndex={this.props.pageIndex}
                    pageSize={this.props.pageSize}
                    onPageIndexChanged={this.props.onPageIndexChanged}
                    onPageSizeChange={this.props.onPageSizeChange}
                />
            </table>
        );
    }

    render() {
        let content =
            this.props.loading || this.props.kitsError ? (
                <div></div>
            ) : (
                this.renderContent()
            );

        return (
            <div id="kit-list" className="columns data-area">
                <KitListFilterHeader
                    status={this.props.filterStatus}
                    query={this.props.filterQuery}
                    onFilterSearchChange={this.props.onFilterSearchChange}
                    onStatusChange={this.props.onStatusChange}
                    onFilterSearchTriggered={this.props.onFilterSearchTriggered}
                />
                <Loader
                    loading={this.props.loading}
                    style={{ marginTop: '50px' }}
                    type={loaderConstants.type.medium}
                >
                    {content}
                </Loader>
                <Error message={this.props.kitsError}></Error>
            </div>
        );
    }
}

KitListContainer.propTypes = {
    filterStatus: PropTypes.string.isRequired,
    filterQuery: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    kitsError: PropTypes.string.isRequired,
    selectableKits: PropTypes.array.isRequired,
    onFilterSearchChange: PropTypes.func.isRequired,
    onFilterSearchTriggered: PropTypes.func.isRequired,
    nonSortableColumns: PropTypes.array.isRequired
};
