import React from 'react';
import PropTypes from 'prop-types';
import ActionLink from '../../shared/actionlink';
import { kitListConstants } from '../../../constants/constants';
import { loc } from '../../../localization/localization';

export default class KitListSortableNode extends React.Component {
    render() {
        const column = this.props.column;
        const sortingCssDesc = 'sort-descending';
        const sortingCssAscending = 'sort-ascending';

        const disableSorting = this.props.nonSortableColumns.includes(
            column.columnType
        );

        let sorting = '';

        switch (column.sortingDirection) {
            case kitListConstants.order.asc:
                sorting = sortingCssAscending;
                break;
            case kitListConstants.order.desc:
                sorting = sortingCssDesc;
                break;
            default:
                sorting = '';
        }

        var columnName = this.translateColumn(column);

        return (
            <th className={'sort-simple ' + sorting}>
                {columnName}

                {!disableSorting && (
                    <ActionLink
                        title=""
                        onClick={() => this.props.onOrderByChange(column)}
                    >
                        <i className="icon sort" />
                    </ActionLink>
                )}
            </th>
        );
    }

    translateColumn(column) {
        let columnTypes = kitListConstants.columnTypes;

        switch (column.columnType) {
            case columnTypes.notSpecified:
                return <span>#</span>;
            case columnTypes.kitNumber:
                return <span> {loc.kitNumber} </span>;
            case columnTypes.kitType:
                return <span>{loc.kitType}</span>;
            case columnTypes.status:
                return <span>{loc.status}</span>;
            case columnTypes.location:
                return <span>{loc.location}</span>;
            case columnTypes.expiryDate:
                return <span>{loc.expiryDate}</span>;
            default:
                throw new Error(
                    `Column type ${column.columnType} is not supported`
                );
        }
    }
}

KitListSortableNode.propTypes = {
    column: PropTypes.exact({
        name: PropTypes.string.isRequired,
        orderBy: PropTypes.string.isRequired,
        sortingDirection: PropTypes.string.isRequired,
        columnType: PropTypes.string.isRequired
    })
};
